import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, initCalendar, clickToScrollToBlock, headerNavLinksHover, ogContent, removeCategURLParamPageDigital, removeCategURLParamPageEvents, refreshEventsList, resetCategsEvents, resetMobileCategsEvents, refreshOverlayCalendar, refreshOverlayMobileCalendar, scrollToBlock, refreshButtonMoreEvents } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, clearInput, inputsAndTextareaLabel } from './functions/form.js'
import { overlayCalendar, overlayCalendarMobile, overlayFilters, overlayMobileMenu, overlayPopup, overlaySearch, overlayShare  } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formExample, formSuccess } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { hundredVH, removeHoverOnMobile } from './functions/helper.js'
import { backToTopAndLinks } from './functions/back-to-top-and-links.js'
import { swiperDigitalContent, swiperFeaturedEvents } from './functions/sliders.js'
import { bannerAlerts } from './functions/banner-alerts.js'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.refreshEventsList = refreshEventsList
window.resetCategsEvents = resetCategsEvents
window.resetMobileCategsEvents = resetMobileCategsEvents
window.refreshOverlayCalendar = refreshOverlayCalendar
window.refreshOverlayMobileCalendar = refreshOverlayMobileCalendar
window.refreshButtonMoreEvents = refreshButtonMoreEvents
window.barba = barba
window.scrollToBlock = scrollToBlock

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    overlaySearch()
    overlayMobileMenu()
    overlayShare()
    overlayPopup()
    inputsAndTextareaLabel()
    clearInput()
    backendKeyBind()
    SCROLLFIRE.init()
    removeHoverOnMobile()
    headerNavLinksHover()
    backToTopAndLinks()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          formExample()
          initCalendar()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          hundredVH()
          bannerAlerts()
          swiperFeaturedEvents()
          swiperDigitalContent()
          clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'errorView',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'culturalPlacesListView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'projectDetailView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'digitalContentListView',
        beforeEnter() {
          overlayFilters()
          new Accordions({ scrollToAccordion: false, closeAllBeforeOpen: false })
          removeCategURLParamPageDigital()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          overlayFilters()
          new Accordions({ scrollToAccordion: false, closeAllBeforeOpen: false })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          overlayFilters()
          overlayCalendar()
          overlayCalendarMobile()
          new Accordions({ scrollToAccordion: false, closeAllBeforeOpen: false })
          removeCategURLParamPageEvents()
          window['lazyload'] = new LazyLoad()
        },
        beforeLeave() {
          localStorage.setItem('scrollPositionCalendar', window.scrollY)
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      }
    ]
  })
}
