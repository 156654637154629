// |--------------------------------------------------------------------------
// | Banner alerts
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner la
// | bannière des alertes.
// |
// | IMPORTS _____
// | -> jQuery
// | -> Observer
// |
// | OPTIONS _____
// | container => Le bloc de la bannière d'alerte.
// | slider => Le sélecteur du 'slider'.
// | slide => Le sélecteur des 'slide'.
// | navigation => Le sélecteur du container contenant 'current', 'total' 
// |               et la navigation.
// | prev => Le sélecteur de 'prev' (pour aller à la slide précédente).
// | next => Le sélecteur de 'next' (pour aller à la slide suivante).
// | close => Le sélecteur du bouton 'close' (pour masquer la bannière).
// | showClass => La classe permettant d'afficher le 'container' et les
// |              'navigation'.
// | show0 => Permet d'afficher un 0 devant 'current' et 'total' en bas de
// |          10.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'
// ---------------------------------------------------------------------------


export function bannerAlerts(options = {}) {

  // Déclaration des propriétés par défaut
  options.container === undefined ? options.container = '[data-js="alerts"]' : options.container
  options.slider === undefined ? options.slider = '[data-js="alerts-slider"]' : options.slider
  options.slide === undefined ? options.slide = '[data-js="alerts-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-js="alerts-navigation"]' : options.navigation
  options.close === undefined ? options.close = '[data-js="alerts-close"]' : options.close
  options.showClass === undefined ? options.showClass = 'displayed' : options.showClass
  options.show0 === undefined ? options.show0 = false : options.show0

  // Déclarations des variables
  let container = document.querySelector(options.container)

  // Déclarations des fonctions
  const closeAlert = () => container.classList.remove('active')

  // Ajout de l événement pour masquer la bannière d'alertes 
  OBSERVER.add({
    name: 'alerts',
    event: 'click',
    function: closeAlert,
    target: options.close
  })
  OBSERVER.on('alerts')

  // Si la page contient le container banner-alert
  if (typeof (container) != 'undefined' && container != null) {
    console.log('')
  } else {
    container.classList.remove('active')
  }
}