import $ from 'jquery'
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper.js'
import { overlayCalendarDesktopFilter, overlayCalendarMobileFilter } from './overlays'

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })
  
  return animation.finished
}


// Permet de créer un calendrier
export function initCalendar(selector = '.calendar') {
  if (!document.querySelector(selector)){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector(selector).dataset.list
  let currentDate = document.querySelector(selector).dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')

  $(selector).datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('eventsIndex::onChangeDate', {
      data: {date: theTimestamp},
      update: {
        'eventsIndex::items':'#items',
        'eventsIndex::calendar':'#overlay-desktop-calendar-filter',
        'eventsIndex::mobile-calendar':'#overlay-mobile-calendar-filter'
      },
      complete: function (data) {
        data.then(function(data) {
          refreshEventsList()
          refreshOverlayCalendar()
          refreshOverlayMobileCalendar()
          refreshButtonMoreEvents(data.hasMorePages)
        })
      },
    })

    //Fermer l'overlay quand on clique
    if(document.querySelector('.show-overlay-calendar')){
      $('.overlay-calendar__close').trigger('click')
    }
    if(document.querySelector('.show-overlay-calendar-mobile')){
      $('.overlay-calendar-mobile__close').trigger('click')
    }
  })

  $(selector).datepicker('update', currentDate)
}


// Copier du texte
export function copyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Ajoute les metas pour le contenu og
export function ogContent(data) {
  let ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
  let ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
  let ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
  let ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
  document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
  document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
  document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
  document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
}


// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}


export function headerNavLinksHover() {
  const selector = '.js-header-links-container a'
  const linksContainer = document.querySelector('.js-header-links-container')
  
  const onMouseenter = e => {
    linksContainer.classList.add('js-one-link-is-active')
    e.currentTarget.classList.add('js-active')
  }
  
  const onMouseleave = e => {
    linksContainer.classList.remove('js-one-link-is-active')
    e.currentTarget.classList.remove('js-active')
  }

  OBSERVER.add({
    name: 'headerNavLinksHover',
    event: 'mouseenter',
    target: selector,
    function: onMouseenter
  })

  OBSERVER.add({
    name: 'headerNavLinksHover',
    event: 'mouseleave',
    target: selector,
    function: onMouseleave
  })

  OBSERVER.on('headerNavLinksHover')
}


// Permet de nettoyer l'URL de la page contenu numéric quand il y a un paramètre categorie
export function removeCategURLParamPageDigital() {
  setTimeout(function() {
    history.replaceState && history.replaceState(null, '', location.pathname + location.search.replace(/[\?&]categorie=[^&]+/, '').replace(/^&/, '?'))
  }, 300)
  
}


// Permet de nettoyer l'URL de la page calendrier quand il y a un paramètre categorie-id
export function removeCategURLParamPageEvents() {
  setTimeout(function() {
    history.replaceState && history.replaceState(null, '', location.pathname + location.search.replace(/[\?&]categorie-id=[^&]+/, '').replace(/^&/, '?'))
  }, 300)
}


// Permet de refresh une partie de la page events par un call ajax
export function refreshEventsList() {
  window.SCROLLFIRE.update()
  scrollToBlock({ scrollTo: '.js-page-banner-calendar-button-container' })
}


// Permet de réinitialiser le filtre de catégorie events
export function resetCategsEvents() {
  let inputsCategs = document.querySelectorAll('input[name^="desktop-filters-1-checkbox"]')
  inputsCategs.forEach( element => { element.checked = false })
}


// Permet de réinitialiser le filtre de catégorie events en mobile
export function resetMobileCategsEvents() {
  let inputsCategs = document.querySelectorAll('input[name^="mobile-filters-1-checkbox"]')
  inputsCategs.forEach( element => { element.checked = false })
}


// Permet de refresh le overlay calendrier
export function refreshOverlayCalendar() {
  let eventsDatesList = document.querySelector('.calendar-desktop').dataset.list
  let currentDate = document.querySelector('.calendar-desktop').dataset.date

  overlayCalendarDesktopFilter.close()
  overlayCalendarDesktopFilter.destroy()
  overlayCalendarDesktopFilter.init()

  $('.calendar-desktop').children().remove()
  document.querySelector('.calendar-desktop').dataset.list = eventsDatesList
  document.querySelector('.calendar-desktop').dataset.date = currentDate
  initCalendar('.calendar-desktop')
}


// Permet de refresh le overlay calendrier en mobile
export function refreshOverlayMobileCalendar() {
  let eventsDatesList = document.querySelector('.calendar-mobile').dataset.list
  let currentDate = document.querySelector('.calendar-mobile').dataset.date

  overlayCalendarMobileFilter.close()
  overlayCalendarMobileFilter.destroy()
  overlayCalendarMobileFilter.init()

  $('.calendar-mobile').children().remove()
  document.querySelector('.calendar-mobile').dataset.list = eventsDatesList
  document.querySelector('.calendar-mobile').dataset.date = currentDate
  initCalendar('.calendar-mobile')
}


// Permet de refresh le bouton more events
export function refreshButtonMoreEvents(hasMorePages) {
  let buttonMore = $('#eventsMore')

  if (hasMorePages) {
    buttonMore.show()
  } else {
    buttonMore.hide()
  }
}