import $ from 'jquery'
import 'eonasdan-bootstrap-datetimepicker'
import { OBSERVER } from '../main'
import { formatBytes, isMobile } from './helper'


// Le package à appliquer pour les formulaires
export function formsPackage() {
  inputsAndTextareaLabel()
  fileUpload()
  tailSelect()
  textareaHeight()
  calendarInput()
  timepicker()
}


// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel() {
  const elementsString = 'input[type="text"], input[type="email"], input[type="search"], input[type="tel"], textarea',
    formElements = document.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i

  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = e => e.target.parentNode.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue
  })

  OBSERVER.on('inputsAndTextareaLabel')
}


// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight() {
  const onInput = e => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`
  }

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    function: onInput
  })

  OBSERVER.on('textareaHeight')
}


// Permet de supprimer le contenu d'un input
export const clearInput = () => {
  const onClick = e => {
    let input = e.currentTarget.parentNode.querySelector('.js-input-to-clear')
    input.value = ''
    input.focus()
    input.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }

  OBSERVER.add({
    name: 'clearInput',
    event: 'click',
    target: '.js-clear-input',
    function: onClick
  })

  OBSERVER.on('clearInput')
}


// Reset les dropzones du formulaire
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  let dropZoneList = $('.fileField', $(formObj))

  // reset des dropzones du form
  dropZoneList.each(function (index, element) {
    // pour chaque drop zone trouvé dans le form
    // on cherche son instance de Dropzone
    let dropZoneInstance = getDropzone(element.id)
    dropZoneInstance.removeAllFiles()
  })
}


// Retourne la dropZone en fonction de son nom
export function getDropzone(name) {
  let selectedDropzone = false
  Dropzone.instances.forEach(function (dropzone) {
    if (dropzone.element.id == name) selectedDropzone = dropzone
  })
  return selectedDropzone
}


// Permet de changer le label des input files
export function fileUpload() {
  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.js-file-text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = e => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i = 0; i < fileLength; i++) {
          let file = e.currentTarget.files[i]
          newLabel += `${(i + 1)}. `

          if ('name' in file) newLabel += `fichier: ${file.name}, `
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`

          const onClear = () => clear(i, self.target, currentLabelText)

          OBSERVER.add({
            name: `clear${i}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            function: onClear
          })

          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file]',
    function: changeLabel
  })

  OBSERVER.on('fileUpload')
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect() {
  let themePath = window.config.theme_path

  if (!isMobile())
    tail.select('select', { animate: false })

  // Ajouter les images dans les options du Tail Select
  let selectBoxes, selectBoxesLength, i, j
  selectBoxes = document.querySelectorAll('.tail-select')
  selectBoxesLength = selectBoxes.length

  for (i = 0; i < selectBoxesLength; i++) { //Looper dans chaques boites de sélections Tail Select
    let tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option')
    let nativeSelect = selectBoxes[i].previousElementSibling
    let nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)')

    // Ajouter l'icone en symbole
    let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/' + themePath + '/assets/medias/images/icons/symbols.svg#ico-pointer')
    svgElem.appendChild(useElem)
    //selectBoxes[i].querySelector('.select-label').appendChild(svgElem)
    selectBoxes[i].appendChild(svgElem)

    for (j = 0; j < nativeSelectItems.length; j++) { //Looper dans chaques item du Tail Select
      let imgPath = nativeSelectItems[j].dataset.image
      if ((typeof imgPath !== 'undefined') && imgPath != '') {
        let newImage = document.createElement('img')
        newImage.src = imgPath
        tailSelectItems[j].classList.add('has-image')
        tailSelectItems[j].appendChild(newImage)
      }
    }
  }

  // Gérer le changement de choix dans le select pour mettre l'image dans la boite du résultat sélectionné
  // Pas utilisé pour le moment
  /*
  OBSERVER.add({
    name: 'tailSelectChange',
    event: 'change',
    root: document,
    target: '.field--select select',
    function: tailSelectChange
  })
  OBSERVER.on('tailSelectChange')

  function tailSelectChange(e){
    var tailActive = e.target.parentElement.querySelector('.label-inner')

    if(tailActive){
      var selectedTailItem = e.target.parentElement.querySelectorAll('.tail-select .dropdown-option')[e.target.selectedIndex-1]
      if(selectedTailItem.classList.contains('has-image')){
        var newImage = document.createElement('img')
        newImage.src = selectedTailItem.querySelector('img').src
        tailActive.appendChild(newImage)
      }
    }
    
  }
  */
}


// Initialisation du champ de timepicker
export function timepicker() {
  if (!isMobile()) {
    $('.form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down'
      },
    })
  }
}


// Initialisation du champ de calendrier
export function calendarInput() {

  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {

    $.fn.datepicker.dates['fr'] = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: 'Aujourd\'hui',
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0
    }

    $('.datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $('.datepickerWrapper').data('startDate')
    })

    $('.input-calendar input').on('focus', function () {
      $(this).parent().parent().find('.datepickerWrapper').addClass('show')
    })

    $('.datepickerWrapper').on('changeDate', function () {
      $(this).prev().find('input').val($(this).datepicker('getFormattedDate'))
      $('.datepickerWrapper').removeClass('show')
    })

    const closeCalendar = () => {
      let i, x = document.querySelectorAll('.datepickerWrapper')

      for (i = 0; i < x.length; i++) 
        x[i].classList.remove('show')
    }
    
    const preventClose = e => e.stopPropagation()

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      function: closeCalendar
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: '.input-calendar',
      function: preventClose
    })

    OBSERVER.on('input-calendar')

    // Si on est en mobile, mettre utiliser les calendriers en HTML5
  } else {
    $('.input-calendar input').attr('type', 'date')
  }
}