// Documentation: https://swiperjs.com/swiper-api
import Swiper from 'swiper/bundle'
import { OBSERVER } from '../main'

export function swiperFeaturedEvents() {
  let imagesSwiper = new Swiper('[data-swiper="events-images"]', {
    effect: 'fade',
    speed: 500,
    threshold: 10,
    loop: true,
  })

  let contentSwiper = new Swiper('[data-swiper="events-content"]', {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    threshold: 10,
    loop: true,
    speed: 500,
    navigation: {
      prevEl: '[data-swiper-prev="events-content"]',
      nextEl: '[data-swiper-next="events-content"]',
    },
    autoplay: {
      delay: 7000,
    },
  })

  contentSwiper.controller.control = imagesSwiper
  imagesSwiper.controller.control = contentSwiper
}

export function swiperDigitalContent() {
  const options = {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: true,
    navigation: {
      prevEl: '[data-swiper-prev="digital-content"]',
      nextEl: '[data-swiper-next="digital-content"]',
    },
    autoplay: {
      delay: 7000,
    },
  }

  
  const swiper = document.querySelector('[data-swiper="digital-content"]')
  
  const onResize = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    
    if (width <= 450) {
      if (swiper.classList.contains('swiper-container-initialized')) {
        window.swiperDigitalContent.destroy()
      }
    } else {
      if (!swiper.classList.contains('swiper-container-initialized')) {
        window.swiperDigitalContent = new Swiper('[data-swiper="digital-content"]', options)
      }
    }
  }

  onResize()

  OBSERVER.add({
    name: 'swiperDigitalContent',
    event: 'resize',
    function: onResize
  })

  OBSERVER.on('swiperDigitalContent')
}