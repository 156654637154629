import { OBSERVER } from './../main.js'
import Overlay from '../vendor/overlay.js'
import ShareButtons from 'share-buttons/dist/share-buttons.js'
import { initCalendar } from './functions'

let overlayCalendarDesktopFilter
let overlayCalendarMobileFilter

export { overlayCalendarDesktopFilter, overlayCalendarMobileFilter }


export function overlayPopup() {
  new Overlay({
    name: 'popup',
    click: {
      buttons: {
        close: '[data-js="close-overlay-popup"]',
      }
    },
    timeout: {
      delay: 2000
    },
    options: {
      speed: 600,
    }
  }).init()
}

export function overlaySearch() {
  new Overlay({
    name: 'search',
    events: {
      openComplete: true
    },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-search"]',
        close: '[data-js="close-overlay-search"], a[href]',
        switch: '[data-js="trigger-overlay-desktop-menu"], [data-js="toggle-overlay-mobile-menu"]'
      }
    },
    animations: {
      addTransition: true,
      selector: '.overlay-search__content-container',
      styles: [{
        property: 'height',
        value: '100%',
        easing: 'easeInOutQuart'
      }]
    },
    options: {
      speed: 600,
      goToSelector: 'html, body',
      closeOnResize: false,
      disabledPageScroll: true
    }
  }).init()

  const onFocus = () => {
    document.getElementById('form-search-input').focus()
  }

  OBSERVER.add({
    name: 'overlaySearch',
    event: 'onOpenCompleteOverlaySearch',
    function: onFocus
  })

  OBSERVER.on('overlaySearch')
}

export function overlayCalendar() {
  overlayCalendarDesktopFilter = new Overlay({
    name: 'calendar',
    click: {
      buttons: {
        open: '[data-js="open-overlay-calendar"]',
        close: '[data-js="close-overlay-calendar"], a[href]',
        switch: '[data-js="toggle-overlay-search"], [data-js="toggle-overlay-mobile-menu"]'
      }
    },
    options: {
      speed: 600,
      disabledPageScroll: false
    }
  })

  initCalendar('.calendar-desktop')
  overlayCalendarDesktopFilter.init()
}

export function overlayCalendarMobile() {
  overlayCalendarMobileFilter = new Overlay({
    name: 'calendar-mobile',
    click: {
      buttons: {
        open: '[data-js="open-overlay-calendar-mobile"]',
        close: '[data-js="close-overlay-calendar-mobile"], a[href]',
        switch: '[data-js="toggle-overlay-search"], [data-js="toggle-overlay-mobile-menu"]'
      }
    },
    options: {
      speed: 600,
      disabledPageScroll: true
    }
  })

  initCalendar('.calendar-mobile')
  overlayCalendarMobileFilter.init()
}

export function overlayMobileMenu() {
  new Overlay({
    name: 'mobile-menu',
    events: {
      close: true
    },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-mobile-menu"]',
        close: '[data-js="close-overlay-mobile-menu"], a[href]',
        switch: '[data-js="toggle-overlay-search"]'
      }
    },
    options: {
      speed: 600,
      goToSelector: 'html, body',
      disabledPageScroll: true
    }
  }).init()
}

export function overlayFilters() {
  new Overlay({
    name: 'filters',
    click: {
      buttons: {
        open: '[data-js="open-overlay-filters"]',
        close: '[data-js="close-overlay-filters"], a[href]',
      }
    },
    options: {
      speed: 600,
      disabledPageScroll: true,
    }
  }).init()
}

export function overlayShare() {
  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 600,
    }
  }).init()

  // Message de confirmation de copie de l'URL ----------------
  function confirmClipboardCopy() {
    alert('L’adresse URL a bien été copiée dans votre presse-papier.')
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    event: 'click',
    target: '.js-share-copy',
    function: confirmClipboardCopy
  })
  OBSERVER.on('confirmClipboardCopy')

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    event: 'click',
    target: '.js-share',
    function: openShareOverlay
  })
  OBSERVER.on('openShareOverlay')

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ')
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'closeShareOverlay',
    event: 'click',
    target: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay
  })
  OBSERVER.on('closeShareOverlay')

}